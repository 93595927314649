import { configureStore } from "@reduxjs/toolkit";
import toggleReducer from "./theme";
import authReducer from "./auth";
import templateReducer from "./template";
import walletReducer from "./wallet";
import toastReducer from "./toast";
import renderReducer from "./render";
import scraperReducer from "./scraper";
//apis
import { api } from "../services/api/api";

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    theme: toggleReducer,
    auth: authReducer,
    template: templateReducer,
    wallet: walletReducer,
    toast: toastReducer,
    scraper: scraperReducer,
    render: renderReducer,
  },
  middleware: (gDM) => gDM().concat(api.middleware),
});

export default store;
