import { createSlice } from "@reduxjs/toolkit";

const initialScraperState = {
  showConfig: false,
};

const scraperSlice = createSlice({
  name: "scraper",
  initialState: initialScraperState,
  reducers: {
    showScraperPop(state, action) {
      state.showConfig = action.payload;
    },
  },
});

export const { showScraperPop } = scraperSlice.actions;

export default scraperSlice.reducer;
