import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./Sidebar.module.css";

const routes = [
  {
    name: "Instance",
    link: "instance",
  },
  {
    name: "My Machines",
    link: "machines",
  },
  {
    name: "How to Host",
    link: "how-to-use",
  },
  // {
  //   name: "Prompt",
  //   link: "prompt",
  // },
  {
    name: "My Instance",
    link: "myinstance",
  },
  {
    name: "Template",
    link: "template",
  },
  {
    name: "Render Farm",
    link: "render",
  },
  {
    name: "LLM Data Builder",
    link: "builder",
  },
  // {
  //   name: "Billing",
  //   link: "transaction",
  // },
  {
    name: "Account",
    link: "account",
  },
];
const comingRoutes = [
  {
    name: "Distributed Training",
  },
  {
    name: "Earning",
  },
];
function Sidebar() {
  return (
    <>
      <div className={classes.widthHolder}></div>
      <div className={classes.sidebar}>
        <ul className={classes.sidebarList}>
          {routes.map((item, index) => (
            <li key={item.name} className={classes.sideItems}>
              <NavLink
                to={item.link}
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
              >
                <img
                  className={classes.icon}
                  src={`/assets/images/sidebar/${index + 1}.png`}
                  alt={item.name}
                />
                <img
                  className={classes.hoverIcon}
                  src={`/assets/images/sidebar/${index + 1}_hover.png`}
                  alt={item.name}
                />
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
        <div className={classes.coming}>COMING SOON</div>
        <ul className={`${classes.sidebarList} ${classes.disabled}`}>
          {comingRoutes.map((item, index) => (
            <li key={item.name} className={classes.sideItems}>
              <a>
                <img
                  className={classes.icon}
                  src={`/assets/images/sidebar/lock.png`}
                  alt={item.name}
                />
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
