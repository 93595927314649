import React from "react";
import classes from "./Button.module.css";

const Button = ({
  children,
  onClick,
  disabled = false,
  full = false,
  theme = "blue",
}) => {
  return (
    <div
      className={`${classes.button} ${disabled && classes.disabled} ${
        full && classes.full
      } ${classes[theme]}`}
      onClick={disabled ? () => {} : onClick}
    >
      {children}
    </div>
  );
};

export default Button;
